exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ai-tsx": () => import("./../../../src/pages/ai.tsx" /* webpackChunkName: "component---src-pages-ai-tsx" */),
  "component---src-pages-asset-gallery-index-tsx": () => import("./../../../src/pages/asset-gallery/index.tsx" /* webpackChunkName: "component---src-pages-asset-gallery-index-tsx" */),
  "component---src-pages-blog-esemie-free-invoice-generator-tsx": () => import("./../../../src/pages/blog/esemie-free-invoice-generator.tsx" /* webpackChunkName: "component---src-pages-blog-esemie-free-invoice-generator-tsx" */),
  "component---src-pages-blog-increase-online-sales-tsx": () => import("./../../../src/pages/blog/increase-online-sales.tsx" /* webpackChunkName: "component---src-pages-blog-increase-online-sales-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-top-5-financial-mistakes-small-businesses-must-avoid-tsx": () => import("./../../../src/pages/blog/top-5-financial-mistakes-small-businesses-must-avoid.tsx" /* webpackChunkName: "component---src-pages-blog-top-5-financial-mistakes-small-businesses-must-avoid-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-customer-tsx": () => import("./../../../src/pages/customer.tsx" /* webpackChunkName: "component---src-pages-customer-tsx" */),
  "component---src-pages-expense-tsx": () => import("./../../../src/pages/expense.tsx" /* webpackChunkName: "component---src-pages-expense-tsx" */),
  "component---src-pages-expiry-tsx": () => import("./../../../src/pages/expiry.tsx" /* webpackChunkName: "component---src-pages-expiry-tsx" */),
  "component---src-pages-generate-free-invoice-tsx": () => import("./../../../src/pages/generate-free-invoice.tsx" /* webpackChunkName: "component---src-pages-generate-free-invoice-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inventory-tsx": () => import("./../../../src/pages/inventory.tsx" /* webpackChunkName: "component---src-pages-inventory-tsx" */),
  "component---src-pages-invoice-tsx": () => import("./../../../src/pages/invoice.tsx" /* webpackChunkName: "component---src-pages-invoice-tsx" */),
  "component---src-pages-localization-tsx": () => import("./../../../src/pages/localization.tsx" /* webpackChunkName: "component---src-pages-localization-tsx" */),
  "component---src-pages-pricings-tsx": () => import("./../../../src/pages/pricings.tsx" /* webpackChunkName: "component---src-pages-pricings-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-programs-app-layout-tsx": () => import("./../../../src/pages/programs/AppLayout.tsx" /* webpackChunkName: "component---src-pages-programs-app-layout-tsx" */),
  "component---src-pages-programs-form-tsx": () => import("./../../../src/pages/programs/form.tsx" /* webpackChunkName: "component---src-pages-programs-form-tsx" */),
  "component---src-pages-programs-index-tsx": () => import("./../../../src/pages/programs/index.tsx" /* webpackChunkName: "component---src-pages-programs-index-tsx" */),
  "component---src-pages-report-tsx": () => import("./../../../src/pages/report.tsx" /* webpackChunkName: "component---src-pages-report-tsx" */),
  "component---src-pages-resource-tsx": () => import("./../../../src/pages/resource.tsx" /* webpackChunkName: "component---src-pages-resource-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */)
}

